import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { getAllPlayers } from "../services/PlayerService";
import { createUser } from "../services/AccountService";
import { getUserAttributes } from "../services/AuthService";

interface CreateAccountModalProps {
  onClose: () => void;
}

const CreateAccountModal: React.FC<CreateAccountModalProps> = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [tempPassword, setTempPassword] = useState("");
  const [selectedPlayerId, setSelectedPlayerId] = useState("");
  const [players, setPlayers] = useState<{ _id: string; name: string }[]>([]);
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const players = await getAllPlayers();
        setPlayers(players);
      } catch (error) {
        console.error("Error fetching players:", error);
      }
    };

    fetchPlayers();

    // Get current user attributes
    const fetchUserAttributes = async () => {
      try {
        const attributes = await getUserAttributes();
        setUserId(attributes["sub"]);
      } catch (error) {
        console.error("Error fetching user attributes:", error);
      }
    };

    fetchUserAttributes();
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === "email") setEmail(value);
    if (name === "tempPassword") setTempPassword(value);
    if (name === "playerId") setSelectedPlayerId(value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const userData = { email, tempPassword, playerId: selectedPlayerId, userId };
      await createUser(userData);
      setMessage("User created successfully!");
    } catch (error) {
      console.error("Error creating user:", error);
      setMessage("An error occurred while creating the user");
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h3>Create Account</h3>
        <form className="modalForm" onSubmit={handleSubmit}>
          <label>
            Email:
            <input type="email" name="email" value={email} onChange={handleChange} required />
          </label>
          <label>
            Temporary Password:
            <input type="password" name="tempPassword" value={tempPassword} onChange={handleChange} required />
          </label>
          <label>
            Select Player:
            <select name="playerId" value={selectedPlayerId} onChange={handleChange} required>
              <option value="" disabled>
                Select a player
              </option>
              {players.map((player) => (
                <option key={player._id} value={player._id}>
                  {player.name}
                </option>
              ))}
            </select>
          </label>
          <button className="saveButton" type="submit">
            Create
          </button>
        </form>
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
};

export default CreateAccountModal;
