import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Fetches all player stats based on filter and contextId
export const getAllPlayerStats = async (filter = "all-time", contextId) => {
  try {
    const response = await api.get("/stats/players", {
      params: {
        filter,
        contextId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch all player stats:", error);
    throw error;
  }
};

// Fetches player stats by game, season, or all-time depending on the filter
export const getPlayerStats = async (playerId, filter = "all-time", contextId) => {
  let endpoint = `/stats/player/${playerId}/all-time`; // Default to all-time

  if (filter === "season" && contextId) {
    endpoint = `/stats/player/${playerId}/season/${contextId}`;
  } else if (filter === "game" && contextId) {
    endpoint = `/stats/player/${playerId}/game/${contextId}`;
  }

  try {
    const response = await api.get(endpoint);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch player stats:", error);
    throw error;
  }
};

// Gets hit locations for a player
export const getHitLocations = async (playerId) => {
  try {
    const response = await api.get("/stats/hit-locations", {
      params: {
        playerId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching hit locations:", error);
    throw error;
  }
};

// Fetches weekly stats for a player, with optional seasonId and recentweek parameters
export const getWeeklyStats = async (playerId, seasonId = null, recentweek = false) => {
  try {
    const params = { playerId };

    if (seasonId) {
      params.seasonId = seasonId;
    }

    if (recentweek) {
      params.recentweek = true;
    }

    const response = await api.get("/stats/weekly-stats", { params });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch weekly stats:", error);
    throw error;
  }
};
