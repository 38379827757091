// services/AccountService.js
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Creates a new user in Cognito using the provided user data
export const createUser = async (userData) => {
  try {
    const response = await api.post("/account/create-user", userData);
    return response.data;
  } catch (error) {
    console.error("Failed to create user:", error);
    throw error;
  }
};
