import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { recordAtBat } from "../services/AtBatService";
import { getPlayerById } from "../services/PlayerService"; // Add this import
import { ReactComponent as BaseballField } from "../assets/baseballFieldAtBat.svg";
import BottomNavBar from "./BottomNavBar";
import TopNavBar from "./TopNavBar";

const resultType = [
  { full: "Hit", short: "H" },
  { full: "Groundout", short: "GO" },
  { full: "Flyout", short: "FO" },
  { full: "Walk", short: "BB" },
  { full: "Strikeout", short: "K" }
];

const baseHitType = [
  { full: "Single", short: "1B" },
  { full: "Double", short: "2B" },
  { full: "Triple", short: "3B" },
  { full: "Homerun", short: "HR" }
];

const hitLocation = [
  "3B", "SS", "Middle", "2B", "1B",
  "LeftField", "LeftCenterField", "CenterField", "RightCenterField", "RightField",
  "DeepLeftField", "DeepLeftCenterField", "DeepCenterField", "DeepRightCenterField", "DeepRightField"
];

const homerunLocations = [
  "HomerunLeft", "HomerunCenter", "HomerunRight"
];

const RecordAtBat: React.FC = () => {
  const { gameId, playerId } = useParams<{ gameId: string; playerId: string }>();
  const navigate = useNavigate();
  const [playerName, setPlayerName] = useState<string>(""); // Add state for player name
  const [result, setResult] = useState("");
  const [baseHit, setBaseHit] = useState("");
  const [hitLocationValue, setHitLocationValue] = useState("");
  const [RBIs, setRBIs] = useState<number | null>(null);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    // Fetch player details
    const fetchPlayerDetails = async () => {
      try {
        const player = await getPlayerById(playerId!); // Fetch player by ID
        setPlayerName(player.name); // Set player name
      } catch (error) {
        console.error("Failed to fetch player details:", error);
      }
    };

    fetchPlayerDetails();
  }, [playerId]);

  useEffect(() => {
    // Enable the submit button if the correct selections are made based on the result
    if (result === "Strikeout" || result === "Walk") {
      setIsSubmitEnabled(true);
    } else if (result === "Hit" && baseHit && (hitLocationValue || baseHit === "Homerun") && RBIs !== null) {
      setIsSubmitEnabled(true);
    } else if ((result === "Groundout" || result === "Flyout") && hitLocationValue && RBIs !== null) {
      setIsSubmitEnabled(true);
    } else {
      setIsSubmitEnabled(false);
    }
  }, [result, baseHit, hitLocationValue, RBIs]);

  const handleResultClick = (selectedResult: string) => {
    setResult(selectedResult);
    setBaseHit("");
    setHitLocationValue("");
    setRBIs(null);
  };

  const handleBaseHitClick = (selectedBaseHit: string) => {
    setBaseHit(selectedBaseHit);
    setHitLocationValue("");
  };

  const handleHitLocationClick = (selectedLocation: string) => {
    setHitLocationValue(selectedLocation);
  };

  const handleRBIClick = (selectedRBIs: number) => {
    setRBIs(selectedRBIs);
  };

  const finalizeAtBat = async () => {
    try {
      const atBatData = {
        resultType: result,
        baseHit: result === "Hit" ? baseHit : undefined,
        hitLocation: result !== "Strikeout" && result !== "Walk" ? hitLocationValue : undefined,
        RBIs: result === "Strikeout" || result === "Walk" ? undefined : RBIs // Ensure RBIs is not included for Strikeout or Walk
      };
      console.log("Sending atBatData:", atBatData);
      await recordAtBat(gameId!, playerId!, atBatData);
      navigate(-1); // Go back to the previous page
    } catch (error) {
      console.error("Failed to record at bat:", error);
      alert("Failed to record at bat. Please try again.");
    }
  };

  useEffect(() => {
    // Initialize region styles and visibility based on the current state
    hitLocation.concat(homerunLocations).forEach(location => {
      const region = document.getElementById(`region-${location}`);
      if (region) {
        region.style.fill = "#007BFF";
        region.style.stroke = "#0056b3";
        region.style.fillOpacity = "0.1";
        region.style.strokeOpacity = "1";

        // Determine visibility based on the current result and base hit type
        if (result === "Hit" && baseHit === "Homerun") {
          region.style.display = location.startsWith("Homerun") ? "block" : "none";
        } else if (result === "Hit" || result === "Groundout" || result === "Flyout") {
          region.style.display = location.startsWith("Homerun") ? "none" : "block";
        } else {
          region.style.display = "none";
        }
      }
    });
  }, [result, baseHit]);

  useEffect(() => {
    // Update the fill color and opacity of the selected region
    if (hitLocationValue) {
      hitLocation.concat(homerunLocations).forEach(location => {
        const region = document.getElementById(`region-${location}`);
        if (region) {
          if (location === hitLocationValue) {
            region.style.fill = "red";
            region.style.fillOpacity = "0.6";
          } else {
            region.style.fill = "#007BFF";
            region.style.fillOpacity = "0.1";
          }
        }
      });
    }
  }, [hitLocationValue]);

  return (
    <div className="mainMenuContainer">
      <TopNavBar />
      <h2>Record At Bat</h2>
      <h3>{playerName}</h3> {/* Display player name */}
      <div className="buttonContainer box">
        <div className="recordAtBatButtonGroup">
          <h3 className="locationHeader">Result</h3>
          <div className="recordAtBatButtonGrid">
            {resultType.map((type) => (
              <button
                key={type.full}
                onClick={() => handleResultClick(type.full)}
                className={`recordAtBatButton ${result === type.full ? "selected" : ""}`}
              >
                {type.short}
              </button>
            ))}
          </div>
        </div>

        {result === "Hit" && (
          <div className="recordAtBatButtonGroup">
            <h3 className="locationHeader">Base Hit</h3>
            <div className="recordAtBatButtonGrid">
              {baseHitType.map((type) => (
                <button
                  key={type.full}
                  onClick={() => handleBaseHitClick(type.full)}
                  className={`recordAtBatButton ${baseHit === type.full ? "selected" : ""}`}
                >
                  {type.short}
                </button>
              ))}
            </div>
          </div>
        )}

        {(result === "Hit" && baseHit && baseHit !== "Homerun") || result === "Groundout" || result === "Flyout" ? (
          <div className="recordAtBatButtonGroup">
            <h3 className="locationHeader">Hit Location</h3>
            <BaseballField className="large-svg" ref={svgRef} onClick={(e) => {
              const target = e.target as SVGElement;
              if (target && target.id.startsWith("region-")) {
                const location = target.id.replace("region-", "");
                handleHitLocationClick(location);
              }
            }} />
          </div>
        ) : null}

        {result === "Hit" && baseHit === "Homerun" && (
          <div className="recordAtBatButtonGroup">
            <h3 className="locationHeader">Homerun Location</h3>
            <BaseballField className="large-svg" ref={svgRef} onClick={(e) => {
              const target = e.target as SVGElement;
              if (target && target.id.startsWith("region-")) {
                const location = target.id.replace("region-", "");
                handleHitLocationClick(location);
              }
            }} />
          </div>
        )}

        {(result === "Hit" && baseHit) || (result === "Groundout" || result === "Flyout") ? (
          <div className="recordAtBatButtonGroup">
            <h3 className="locationHeader">RBIs</h3>
            <div className="recordAtBatButtonGrid">
              {baseHit === "Homerun"
                ? [1, 2, 3, 4].map((rbi) => (
                  <button
                    key={rbi}
                    onClick={() => handleRBIClick(rbi)}
                    className={`recordAtBatButton ${RBIs === rbi ? "selected" : ""}`}
                  >
                    {rbi}
                  </button>
                ))
                : [0, 1, 2, 3].map((rbi) => (
                  <button
                    key={rbi}
                    onClick={() => handleRBIClick(rbi)}
                    className={`recordAtBatButton ${RBIs === rbi ? "selected" : ""}`}
                  >
                    {rbi}
                  </button>
                ))}
            </div>
          </div>
        ) : null}

        <button
          className="recordAtBatActionButton"
          onClick={finalizeAtBat}
          disabled={!isSubmitEnabled}
        >
                    Submit
        </button>
      </div>
      <BottomNavBar />
    </div>
  );
};

export default RecordAtBat;
