import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated, getUserAttributes } from "../services/AuthService";

interface PrivateRouteProps<T = object> {
  element: React.ComponentType<T>;
  adminOnly?: boolean;
  [key: string]: unknown;
}

const PrivateRoute = <T extends object>({ element: Component, adminOnly = false, ...rest }: PrivateRouteProps<T>) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const authenticated = await isAuthenticated();
        setIsAuth(authenticated);
        if (authenticated) {
          const userAttributes = await getUserAttributes();
          setIsAdmin(userAttributes["custom:isAdmin"] === "true");
        }
      } catch {
        setIsAuth(false);
      }
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  if (adminOnly && !isAdmin) {
    return <Navigate to="/not-authorized" />;
  }

  return <Component {...(rest as T)} />;
};

export default PrivateRoute;
