import React, { useState, ChangeEvent, FormEvent } from "react";
import { addTeam } from "../services/TeamService"; // Assuming you have a service to handle team creation

interface AddTeamModalProps {
  onClose: () => void;
}

const AddTeamModal: React.FC<AddTeamModalProps> = ({ onClose }) => {
  const [team, setTeam] = useState({
    name: "",
    logo: "", // New field for entering the logo name or path
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTeam((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await addTeam(team); // Call the addTeam function from the service to add the new team
      alert("Team added successfully!");
      onClose();
    } catch (error) {
      if (error instanceof Error) {
        alert(`Error: ${error.message}`);
      } else {
        alert("An unexpected error occurred.");
      }
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Add New Team</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Team Name:
            <input type="text" name="name" value={team.name} onChange={handleChange} placeholder="Enter team name" required />
          </label>
          <label>
            Logo Name:
            <input type="text" name="logo" value={team.logo} onChange={handleChange} placeholder="Enter logo file name" required />
          </label>
          <button type="submit" className="actionButton">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddTeamModal;
