import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTeams } from "../services/TeamService";
import AddTeamModal from "./AddTeamModal"; // Assuming this modal is in the same directory
import TopNavBar from "./TopNavBar";
import BottomNavBar from "./BottomNavBar";

interface Team {
  _id: string;
  name: string;
  logo: string;
}

const ManageTeams: React.FC = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [isAddTeamModalOpen, setIsAddTeamModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const teamsData = await getTeams();
        setTeams(teamsData);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    fetchTeams();
  }, []);

  const handleAddTeam = () => {
    setIsAddTeamModalOpen(true);
  };

  const handleModalClose = () => {
    setIsAddTeamModalOpen(false);
    // Optionally, you could refetch teams here to update the table after adding a team
  };

  return (
    <div className="mainMenuContainer">
      <TopNavBar />
      <h2>Manage Teams</h2>
      <button className="addFab" onClick={handleAddTeam}>
        +
      </button>

      <div className="teamTableContainer">
        <table>
          <thead>
            <tr>
              <th>Team Name</th>
              <th>Logo</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {teams.map((team) => (
              <tr key={team._id}>
                <td>{team.name}</td>
                <td>
                  <img src={`/logos/${team.logo}.png`} alt={team.name} width="50" height="50" />
                </td>
                <td>
                  <button className="editButton" onClick={() => navigate(`/edit-team/${team._id}`)}>
                    Edit
                  </button>
                  <button className="deleteButton">Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isAddTeamModalOpen && <AddTeamModal onClose={handleModalClose} />}
      <BottomNavBar />
    </div>
  );
};

export default ManageTeams;
