import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaUserCircle } from "react-icons/fa";
import logo from "../assets/logo.png";
import ProfileModal from "./ProfileModal"; // Import the ProfileModal component
import { signOut } from "../services/AuthService"; // Import the signOut function

const TopNavBar: React.FC = () => {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const openProfileModal = () => {
    setIsProfileModalOpen(true);
  };

  const closeProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/login");
  };

  return (
    <div className="topNavBar">
      <button className="navButton" style={{ visibility: location.pathname === "/" ? "hidden" : "visible" }} onClick={() => navigate(-1)}>
        <FaArrowLeft />
      </button>
      <img src={logo} alt="Logo" className="logoIcon" />
      <button className="navButton" onClick={openProfileModal}>
        <FaUserCircle />
      </button>
      {isProfileModalOpen && <ProfileModal onClose={closeProfileModal} onSignOut={handleSignOut} />}
    </div>
  );
};

export default TopNavBar;
