import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Fetches all seasons
export const getAllSeasons = async () => {
  try {
    const response = await api.get("/seasons");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch seasons:", error);
    throw error;
  }
};

// Fetches the current season
export const getCurrentSeason = async () => {
  try {
    const response = await api.get("/seasons/current");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch current season:", error);
    throw error;
  }
};

// Fetches a season by ID
export const getSeasonById = async (seasonId) => {
  try {
    const response = await api.get(`/seasons/${seasonId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch season:", error);
    throw error;
  }
};

// Fetches all games for a season
export const addSeason = async (seasonData) => {
  try {
    const response = await api.post("/seasons", seasonData);
    return response.data;
  } catch (error) {
    console.error("Failed to add season:", error);
    throw error;
  }
};

// Adds a game to a season
export const addGameToSeason = async (seasonId, game) => {
  try {
    const response = await api.post(`/seasons/${seasonId}/add-game`, game);
    return response.data;
  } catch (error) {
    console.error("Failed to add game to season:", error);
    throw error;
  }
};

// Updates a season
export const updateSeason = async (id, seasonData) => {
  try {
    const response = await api.put(`/seasons/${id}`, seasonData);
    return response.data;
  } catch (error) {
    console.error("Failed to update season:", error);
    throw error;
  }
};

// Deletes a season
export const deleteSeason = async (seasonId) => {
  try {
    const response = await api.delete(`/seasons/${seasonId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to delete season:", error);
    throw error;
  }
};
