import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSeasonById } from "../services/SeasonService";
import TopNavBar from "./TopNavBar";
import BottomNavBar from "./BottomNavBar";

interface Team {
  _id: string;
  name: string;
}

interface Season {
  _id: string;
  season: string;
  location: string;
  league: string;
  teams: Team[];
  current: boolean;
  startDate: Date;
}

const SeasonTeams: React.FC = () => {
  const { seasonId } = useParams<{ seasonId: string }>();
  const [season, setSeason] = useState<Season | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchSeason = async () => {
      try {
        const fetchedSeason = await getSeasonById(seasonId);
        setSeason(fetchedSeason);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError("Failed to fetch season");
        console.error("Failed to fetch season:", error instanceof Error ? error : "An unexpected error occurred while fetching season");
      }
    };

    fetchSeason();
  }, [seasonId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!season) {
    return <div>No season data available.</div>;
  }

  return (
    <div className="mainMenuContainer">
      <TopNavBar />
      <h2>Teams in {season.season}</h2>
      <table>
        <thead>
          <tr>
            <th>Team</th>
          </tr>
        </thead>
        <tbody>
          {season.teams.map((team) => (
            <tr key={team._id}>
              <td>{team.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <BottomNavBar />
    </div>
  );
};

export default SeasonTeams;
