import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { addSeason } from "../services/SeasonService";
import { getTeams } from "../services/TeamService"; // Import the function to fetch teams
import TopNavBar from "./TopNavBar";
import BottomNavBar from "./BottomNavBar";

interface Team {
  _id: string;
  name: string;
}

const CreateSeason: React.FC = () => {
  const [season, setSeason] = useState({
    season: "",
    location: "",
    league: "",
    teams: [] as string[], // Now an array to hold selected teams
    startDate: new Date().toISOString().split("T")[0], // Set startDate to the current day
    current: false,
    schedule: [],
  });
  const [teams, setTeams] = useState<Team[]>([]); // Holds the list of teams fetched from the API
  const navigate = useNavigate();

  // Fetch the teams when the component mounts
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const teamsData = await getTeams();
        setTeams(teamsData);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };

    fetchTeams();
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    const isCheckbox = e.target.type === "checkbox";
    setSeason((prev) => ({
      ...prev,
      [name]: isCheckbox ? (e.target as HTMLInputElement).checked : value,
    }));
  };

  // Handle changes to the teams selection
  const handleTeamsChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(e.target.selectedOptions).map((option) => option.value);
    setSeason((prev) => ({
      ...prev,
      teams: selectedOptions,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await addSeason(season);
      alert("Season created successfully!");
      navigate("/manage-seasons");
    } catch (error) {
      if (error instanceof Error) {
        alert(`Error: ${error.message}`);
      } else {
        alert("An unexpected error occurred");
      }
    }
  };

  return (
    <div className="mainMenuContainer">
      <TopNavBar />
      <h2>Create New Season</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Season:
          <input type="text" name="season" value={season.season} onChange={handleChange} required />
        </label>
        <label>
          Location:
          <input type="text" name="location" value={season.location} onChange={handleChange} required />
        </label>
        <label>
          League:
          <input type="text" name="league" value={season.league} onChange={handleChange} required />
        </label>
        <label>
          Teams:
          <select name="teams" multiple value={season.teams} onChange={handleTeamsChange} required>
            {teams.map((team) => (
              <option key={team._id} value={team._id}>
                {team.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Start Date:
          <input type="date" name="startDate" value={season.startDate} onChange={handleChange} required />
        </label>
        <label>
          Current Season?
          <input type="checkbox" name="current" checked={season.current} onChange={handleChange} />
        </label>
        <button type="submit" className="actionButton">
          Submit
        </button>
      </form>
      <BottomNavBar />
    </div>
  );
};

export default CreateSeason;
