import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

interface Player {
  _id: string;
  name: string;
  positions: string[];
  status: string;
  batsThrows: string;
}

interface LineupModalProps {
  isOpen: boolean;
  players: Player[];
  onClose: () => void;
  onConfirm: (battingOrder: Player[]) => void;
}

const LineupModal: React.FC<LineupModalProps> = ({ isOpen, players, onClose, onConfirm }) => {
  const [selectedPlayers, setSelectedPlayers] = useState<{ [key: string]: boolean }>({});
  const [battingOrder, setBattingOrder] = useState<Player[]>([]);

  const handlePlayerSelect = (player: Player) => {
    setSelectedPlayers((prev) => ({
      ...prev,
      [player._id]: !prev[player._id],
    }));
    setBattingOrder((prev) => (!selectedPlayers[player._id] ? [...prev, player] : prev.filter((p) => p._id !== player._id)));
  };

  const handleConfirm = () => {
    onConfirm(battingOrder);
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Set Batting Order</ModalHeader>
      <ModalBody>
        <h3>Select Players:</h3>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Player Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {players.map((player: Player) => (
              <tr key={player._id}>
                <td>
                  <input type="checkbox" checked={selectedPlayers[player._id] || false} onChange={() => handlePlayerSelect(player)} />
                </td>
                <td>{player.name}</td>
                <td>{player.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <h3>Batting Order:</h3>
        <ol>
          {battingOrder.map((player) => (
            <li key={player._id}>{player.name}</li>
          ))}
        </ol>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleConfirm}>
          Start Game
        </Button>{" "}
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LineupModal;
