import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllSeasons, deleteSeason } from "../services/SeasonService";
import { getUserAttributes } from "../services/AuthService"; // Import the getUserAttributes function
import TopNavBar from "./TopNavBar";
import BottomNavBar from "./BottomNavBar";
import AddGameModal from "./AddGameModal";
import { Popover, PopoverBody, Button } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";

interface Team {
  _id: string;
  name: string;
  logo: string;
}
interface Season {
  _id: string;
  season: string;
  location: string;
  league: string;
  current: boolean;
  startDate: Date;
  teams?: Team[];
}

const ManageSeasons: React.FC = () => {
  const [seasons, setSeasons] = useState<Season[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedSeason, setSelectedSeason] = useState<Season | null>(null);
  const [popoverOpen, setPopoverOpen] = useState<{ [key: string]: boolean }>({});
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isUserFetched, setIsUserFetched] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchSeasons = async () => {
    try {
      setLoading(true);
      const data = await getAllSeasons();
      const sortedSeasons = data.sort((a: Season, b: Season) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
      setSeasons(sortedSeasons);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Failed to fetch seasons");
      console.error("Error fetching seasons:", error instanceof Error ? error.message : "An unexpected error occurred while fetching seasons");
    }
  };

  const fetchUserAttributes = async () => {
    try {
      const attributes = await getUserAttributes();
      console.log("User attributes:", attributes);
      setIsAdmin(attributes["custom:isAdmin"] === "true");
      setIsUserFetched(true);
    } catch (error) {
      console.error("Error fetching user attributes:", error);
      setIsUserFetched(true);
    }
  };

  const handleDeleteSeason = async (seasonId: string) => {
    if (window.confirm("Are you sure you want to delete this season?")) {
      try {
        await deleteSeason(seasonId);
        setSeasons((prev) => prev.filter((s) => s._id !== seasonId));
      } catch (error) {
        console.error("Failed to delete season:", error);
        alert("Error deleting season");
      }
    }
  };

  const handleAddGames = (season: Season) => {
    setSelectedSeason(season);
    setShowModal(true);
  };

  const togglePopover = (seasonId: string) => {
    setPopoverOpen((prev) => ({ ...prev, [seasonId]: !prev[seasonId] }));
  };

  useEffect(() => {
    fetchSeasons();
    fetchUserAttributes();
  }, []);

  const getSeasonStatus = (season: Season) => {
    const today = new Date();
    const seasonStartDate = new Date(season.startDate);

    if (season.current) {
      return { text: "Current", color: "green" };
    } else if (seasonStartDate > today) {
      return { text: "Future", color: "black" };
    } else {
      return { text: "Past", color: "red" };
    }
  };

  return (
    <div className="mainMenuContainer">
      <TopNavBar />
      <h2>Seasons</h2>
      <button className="addFab" onClick={() => navigate("/create-season")}>
        +
      </button>
      {error && <p className="error">{error}</p>}
      {loading || !isUserFetched ? (
        <div className="loadingSpinner">Loading...</div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Year</th>
              <th>Season</th>
              <th>Location</th>
              <th>League</th>
              <th>Status</th>
              {isAdmin && <th>Admin</th>}
            </tr>
          </thead>
          <tbody>
            {seasons.map((season) => {
              const { text, color } = getSeasonStatus(season);
              return (
                <tr key={season._id}>
                  <td>{new Date(season.startDate).getFullYear()}</td>
                  <td>{season.season}</td>
                  <td>{season.location}</td>
                  <td>{season.league}</td>
                  <td style={{ color }}>{text}</td>
                  {isAdmin && (
                    <td>
                      <Button id={`Popover-${season._id}`} className="editButton">
                        Actions
                      </Button>
                      <Popover
                        placement="bottom"
                        isOpen={popoverOpen[season._id] || false}
                        target={`Popover-${season._id}`}
                        toggle={() => togglePopover(season._id)}
                        className="popoverAction"
                      >
                        <PopoverBody className="popoverBody">
                          <button className="popoverItem" onClick={() => navigate(`/season-games/${season._id}`)}>
                            View Games
                          </button>
                          <button className="popoverItem" onClick={() => navigate(`/season-teams/${season._id}`)}>
                            View Teams
                          </button>
                          <button className="popoverItem" onClick={() => navigate(`/edit-season/${season._id}`)}>
                            Edit
                          </button>
                          <button className="popoverItem" onClick={() => handleDeleteSeason(season._id)}>
                            Delete
                          </button>
                          <button className="popoverItem" onClick={() => handleAddGames(season)}>
                            Add Games
                          </button>
                        </PopoverBody>
                      </Popover>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <BottomNavBar />
      {showModal && selectedSeason && <AddGameModal season={selectedSeason} onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default ManageSeasons;
