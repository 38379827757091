import React, { useState, ChangeEvent, FormEvent } from "react";

interface Team {
  _id: string;
  name: string;
}

interface Game {
  _id: string;
  date: string;
  opponent: Team;
  gameNumber?: number;
  gameType: string;
  status: string;
  players: string[];
  field?: string;
  finalScore?: {
    ourTeam: number;
    otherTeam: number;
  };
}

interface EditGameModalProps {
  game: Game;
  onSave: (updatedGame: Game) => void;
  onClose: () => void;
}

const EditGameModal: React.FC<EditGameModalProps> = ({ game, onSave, onClose }) => {
  const convertUTCToMST = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      timeZone: "America/Phoenix",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const [date, setDate] = useState(new Date(game.date).toISOString().split("T")[0]);
  const [time, setTime] = useState(convertUTCToMST(game.date).substr(0, 5)); // Adjusted for MST time
  const [opponent, setOpponent] = useState(game.opponent._id); // Store the opponent's _id as string
  const [field, setField] = useState(game.field || "");
  const [finalScore, setFinalScore] = useState({
    ourTeam: game.finalScore?.ourTeam || 0,
    otherTeam: game.finalScore?.otherTeam || 0,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === "date") {
      setDate(value);
    } else if (name === "time") {
      setTime(value);
    } else if (name === "opponent") {
      setOpponent(value); // This should be the _id of the opponent
    } else if (name === "field") {
      setField(value);
    } else if (name === "ourTeam") {
      setFinalScore((prev) => ({ ...prev, ourTeam: parseInt(value, 10) }));
    } else if (name === "otherTeam") {
      setFinalScore((prev) => ({ ...prev, otherTeam: parseInt(value, 10) }));
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const updatedDate = new Date(`${date}T${time}:00`).toISOString();
    onSave({
      ...game,
      date: updatedDate,
      opponent: { _id: opponent, name: game.opponent.name },
      field,
      finalScore,
    });
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h3>Edit Game</h3>
        <form className="modalForm" onSubmit={handleSubmit}>
          <label>
            Date:
            <input type="date" name="date" value={date} onChange={handleChange} required />
          </label>
          <label>
            Time:
            <input type="time" name="time" value={time} onChange={handleChange} required />
          </label>
          <label>
            Opponent:
            <input type="text" name="opponent" value={opponent} onChange={handleChange} required />
          </label>
          <label>
            Field:
            <input type="text" name="field" value={field} onChange={handleChange} />
          </label>
          <label>
            Final Score - Our Team:
            <input type="number" name="ourTeam" value={finalScore.ourTeam} onChange={handleChange} />
          </label>
          <label>
            Final Score - Other Team:
            <input type="number" name="otherTeam" value={finalScore.otherTeam} onChange={handleChange} />
          </label>
          <button className="saveButton" type="submit">
            Save
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditGameModal;
