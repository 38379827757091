import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSeasonById, updateSeason } from "../services/SeasonService"; // Import team service
import { getTeams } from "../services/TeamService"; // Import getTeams function
import TopNavBar from "./TopNavBar";
import BottomNavBar from "./BottomNavBar";

interface Team {
  _id: string;
  name: string;
}

interface Season {
  season: string;
  location: string;
  league: string;
  teams: string[]; // Array of team IDs
  current: boolean;
  startDate: string;
}

const EditSeason: React.FC = () => {
  const [season, setSeason] = useState<Season>({
    season: "",
    location: "",
    league: "",
    teams: [],
    current: false,
    startDate: "",
  });

  const [allTeams, setAllTeams] = useState<Team[]>([]); // List of all available teams
  const { seasonId } = useParams<{ seasonId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSeason = async () => {
      if (seasonId) {
        try {
          const seasonData = await getSeasonById(seasonId);
          setSeason({
            ...seasonData,
            startDate: seasonData.startDate.split("T")[0], // Remove time from date
          });
        } catch (error) {
          console.error("Failed to fetch season details:", error);
          alert("Failed to fetch season details");
        }
      }
    };

    const fetchTeams = async () => {
      try {
        const teams = await getTeams();
        setAllTeams(teams); // Populate available teams
      } catch (error) {
        console.error("Failed to fetch teams:", error);
      }
    };

    fetchSeason();
    fetchTeams();
  }, [seasonId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = event.target;
    const isCheckbox = type === "checkbox";
    setSeason((prev) => ({
      ...prev,
      [name]: isCheckbox ? (event.target as HTMLInputElement).checked : value,
    }));
  };

  const handleTeamsChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map((option) => option.value);
    setSeason((prev) => ({
      ...prev,
      teams: selectedOptions, // Update teams with selected team IDs
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await updateSeason(seasonId, season);
      alert("Season updated successfully!");
      navigate("/manage-seasons");
    } catch (error) {
      if (error instanceof Error) {
        console.error("Failed to update season:", error.message);
        alert("Failed to update season. If the issue persists, please contact support.");
      } else {
        console.error("An unexpected error occurred trying to update season");
        alert("Failed to update season. If the issue persists, please contact support.");
      }
    }
  };

  return (
    <div className="mainMenuContainer">
      <TopNavBar />
      <h2>Edit Season</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Season:
          <input type="text" name="season" value={season.season} onChange={handleChange} required />
        </label>
        <label>
          Location:
          <input type="text" name="location" value={season.location} onChange={handleChange} required />
        </label>
        <label>
          League:
          <input type="text" name="league" value={season.league} onChange={handleChange} required />
        </label>
        <label>
          Teams:
          <select name="teams" multiple value={season.teams} onChange={handleTeamsChange} required>
            {allTeams.map((team) => (
              <option key={team._id} value={team._id}>
                {team.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Start Date:
          <input type="date" name="startDate" value={season.startDate} onChange={handleChange} required />
        </label>
        <label>
          Current Season:
          <input type="checkbox" name="current" checked={season.current} onChange={handleChange} />
        </label>
        <button type="submit" className="actionButton">
          Update Season
        </button>
      </form>
      <BottomNavBar />
    </div>
  );
};

export default EditSeason;
