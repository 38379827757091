import React, { useState, useEffect } from "react";
import { getAllPlayers } from "../services/PlayerService";
import { getAllPlayerStats } from "../services/StatsService";
import { getAllSeasons } from "../services/SeasonService";
import { getGamesBySeasonId } from "../services/GameService";
import TopNavBar from "./TopNavBar";
import BottomNavBar from "./BottomNavBar";

interface Player {
  _id: string;
  name: string;
  status: string;
}

interface PlayerStats {
  player: string;
  G: number;
  AB: number;
  H: number;
  "2B": number;
  "3B": number;
  HR: number;
  RBI: number;
  BB: number;
  SO: number;
  AVG: string;
  OBP: string;
  SLG: string;
  OPS: string;
}

interface Season {
  _id: string;
  season: string;
}

interface Game {
  _id: string;
  date: string;
}

const ViewPlayerStats: React.FC = () => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [stats, setStats] = useState<PlayerStats[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>("all-time"); // 'all-time', 'season', 'game'
  const [contextId, setContextId] = useState<string | undefined>(undefined);
  const [seasons, setSeasons] = useState<Season[]>([]);
  const [games, setGames] = useState<Game[]>([]);
  const [selectedSeason, setSelectedSeason] = useState<string | undefined>(undefined);
  const [sortField, setSortField] = useState<keyof PlayerStats>("player");
  const [sortDirection, setSortDirection] = useState<string>("asc");
  const [totalGames, setTotalGames] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const playersData = await getAllPlayers();
        const activePlayers = playersData.filter((player: Player) => player.status !== "Inactive");
        setPlayers(activePlayers);
      } catch (error) {
        console.error("Error fetching players:", error);
      }
    };

    fetchPlayers();
  }, []);

  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const seasonsData = await getAllSeasons();
        setSeasons(seasonsData);
      } catch (error) {
        console.error("Error fetching seasons:", error);
      }
    };

    fetchSeasons();
  }, []);

  useEffect(() => {
    const fetchStats = async () => {
      if (selectedFilter === "season" && !contextId) {
        return;
      }

      setLoading(true);
      try {
        const statsData = await getAllPlayerStats(selectedFilter, contextId);
        const filteredStats = statsData.filter((stat: PlayerStats) => stat.G > 0);
        setStats(filteredStats);

        const maxGamesPlayed = filteredStats.reduce((max: number, stat: PlayerStats) => Math.max(max, stat.G), 0);
        setTotalGames(maxGamesPlayed);
      } catch (error) {
        console.error("Error fetching player stats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [selectedFilter, contextId]);

  useEffect(() => {
    const fetchGamesForSeason = async (seasonId: string) => {
      try {
        const gamesData = await getGamesBySeasonId(seasonId);
        setGames(gamesData);
      } catch (error) {
        console.error("Error fetching games for season:", error);
      }
    };

    if (selectedSeason) {
      fetchGamesForSeason(selectedSeason);
    }
  }, [selectedSeason]);

  const handleSort = (field: keyof PlayerStats) => {
    const direction = sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(direction);
    const sortedStats = [...stats].sort((a, b) => {
      if (a[field] < b[field]) return direction === "asc" ? -1 : 1;
      if (a[field] > b[field]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setStats(sortedStats);
  };

  const calculateTeamTotalStats = () => {
    const totalStats = stats.reduce(
      (acc, stat) => {
        acc.G = Math.max(acc.G, stat.G);
        acc.AB += stat.AB;
        acc.H += stat.H;
        acc["2B"] += stat["2B"];
        acc["3B"] += stat["3B"];
        acc.HR += stat.HR;
        acc.RBI += stat.RBI;
        acc.BB += stat.BB;
        acc.SO += stat.SO;
        return acc;
      },
      {
        G: 0,
        AB: 0,
        H: 0,
        "2B": 0,
        "3B": 0,
        HR: 0,
        RBI: 0,
        BB: 0,
        SO: 0,
      }
    );

    const battingAverage = totalStats.AB ? (totalStats.H / totalStats.AB).toFixed(3) : "0.000";
    const onBasePercentage = totalStats.AB + totalStats.BB ? ((totalStats.H + totalStats.BB) / (totalStats.AB + totalStats.BB)).toFixed(3) : "0.000";
    const sluggingPercentage = totalStats.AB
      ? (
        (totalStats.H - totalStats["2B"] - totalStats["3B"] - totalStats.HR + 2 * totalStats["2B"] + 3 * totalStats["3B"] + 4 * totalStats.HR) /
          totalStats.AB
      ).toFixed(3)
      : "0.000";
    const OPS = (parseFloat(onBasePercentage) + parseFloat(sluggingPercentage)).toFixed(3);

    return {
      ...totalStats,
      AVG: battingAverage,
      OBP: onBasePercentage,
      SLG: sluggingPercentage,
      OPS: OPS,
    };
  };

  const teamTotalStats = calculateTeamTotalStats();

  const renderSortIcon = (field: keyof PlayerStats) => {
    if (sortField === field) {
      return sortDirection === "asc" ? "▲" : "▼";
    }
    return null;
  };

  const activePlayerStats = stats.filter((stat) => players.some((player) => player.name === stat.player));
  const qualifiedPlayers = activePlayerStats.filter((stat) => stat.G >= Math.ceil(totalGames / 2));
  const unqualifiedPlayers = activePlayerStats.filter((stat) => stat.G < Math.ceil(totalGames / 2));

  return (
    <div className="mainMenuContainer">
      <TopNavBar />

      <div className="filter-container">
        <span
          className={`text-filter-option ${selectedFilter === "all-time" ? "active" : ""}`}
          onClick={() => {
            setSelectedFilter("all-time");
            setContextId(undefined);
          }}
        >
          All-Time
        </span>
        <span
          className={`text-filter-option ${selectedFilter === "season" ? "active" : ""}`}
          onClick={() => {
            setSelectedFilter("season");
            setContextId(selectedSeason || undefined);
          }}
        >
          Season
        </span>
        <span
          className={`text-filter-option ${selectedFilter === "game" ? "active" : ""}`}
          onClick={() => {
            setSelectedFilter("game");
            setContextId(undefined);
          }}
        >
          Game
        </span>
      </div>

      {selectedFilter === "season" && (
        <div className="dropdown-container">
          <select
            onChange={(e) => {
              setSelectedSeason(e.target.value);
              setContextId(e.target.value);
            }}
            value={selectedSeason || ""}
            className="filter-select"
          >
            <option value="" disabled>
              Select Season
            </option>
            {seasons.map((season) => (
              <option key={season._id} value={season._id}>
                {season.season}
              </option>
            ))}
          </select>
        </div>
      )}

      {selectedFilter === "game" && selectedSeason && (
        <div className="dropdown-container">
          <select onChange={(e) => setContextId(e.target.value)} value={contextId || ""} className="filter-select">
            <option value="" disabled>
              Select Game
            </option>
            {games.map((game) => (
              <option key={game._id} value={game._id}>
                {game.date}
              </option>
            ))}
          </select>
        </div>
      )}

      <h3>Qualified Players</h3>
      {loading ? (
        <p>Loading stats...</p>
      ) : (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th onClick={() => handleSort("player")} className="sortable-header">
                  Player {renderSortIcon("player")}
                </th>
                <th onClick={() => handleSort("G")} className="sortable-header">
                  G {renderSortIcon("G")}
                </th>
                <th onClick={() => handleSort("AB")} className="sortable-header">
                  AB {renderSortIcon("AB")}
                </th>
                <th onClick={() => handleSort("H")} className="sortable-header">
                  H {renderSortIcon("H")}
                </th>
                <th onClick={() => handleSort("2B")} className="sortable-header">
                  2B {renderSortIcon("2B")}
                </th>
                <th onClick={() => handleSort("3B")} className="sortable-header">
                  3B {renderSortIcon("3B")}
                </th>
                <th onClick={() => handleSort("HR")} className="sortable-header">
                  HR {renderSortIcon("HR")}
                </th>
                <th onClick={() => handleSort("RBI")} className="sortable-header">
                  RBI {renderSortIcon("RBI")}
                </th>
                <th onClick={() => handleSort("AVG")} className="sortable-header">
                  AVG {renderSortIcon("AVG")}
                </th>
                <th onClick={() => handleSort("OBP")} className="sortable-header">
                  OBP {renderSortIcon("OBP")}
                </th>
                <th onClick={() => handleSort("SLG")} className="sortable-header">
                  SLG {renderSortIcon("SLG")}
                </th>
                <th onClick={() => handleSort("OPS")} className="sortable-header">
                  OPS {renderSortIcon("OPS")}
                </th>
              </tr>
            </thead>
            <tbody>
              {qualifiedPlayers.map((stat, index) => (
                <tr key={index}>
                  <td>{stat.player}</td>
                  <td>{stat.G}</td>
                  <td>{stat.AB}</td>
                  <td>{stat.H}</td>
                  <td>{stat["2B"]}</td>
                  <td>{stat["3B"]}</td>
                  <td>{stat.HR}</td>
                  <td>{stat.RBI}</td>
                  <td>{stat.AVG}</td>
                  <td>{stat.OBP}</td>
                  <td>{stat.SLG}</td>
                  <td>{stat.OPS}</td>
                </tr>
              ))}
              <tr>
                <td>Team Total</td>
                <td>{teamTotalStats.G}</td>
                <td>{teamTotalStats.AB}</td>
                <td>{teamTotalStats.H}</td>
                <td>{teamTotalStats["2B"]}</td>
                <td>{teamTotalStats["3B"]}</td>
                <td>{teamTotalStats.HR}</td>
                <td>{teamTotalStats.RBI}</td>
                <td>{teamTotalStats.AVG}</td>
                <td>{teamTotalStats.OBP}</td>
                <td>{teamTotalStats.SLG}</td>
                <td>{teamTotalStats.OPS}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <h3>Unqualified Players</h3>
      {loading ? (
        <p>Loading stats...</p>
      ) : (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th onClick={() => handleSort("player")} className="sortable-header">
                  Player {renderSortIcon("player")}
                </th>
                <th onClick={() => handleSort("G")} className="sortable-header">
                  G {renderSortIcon("G")}
                </th>
                <th onClick={() => handleSort("AB")} className="sortable-header">
                  AB {renderSortIcon("AB")}
                </th>
                <th onClick={() => handleSort("H")} className="sortable-header">
                  H {renderSortIcon("H")}
                </th>
                <th onClick={() => handleSort("2B")} className="sortable-header">
                  2B {renderSortIcon("2B")}
                </th>
                <th onClick={() => handleSort("3B")} className="sortable-header">
                  3B {renderSortIcon("3B")}
                </th>
                <th onClick={() => handleSort("HR")} className="sortable-header">
                  HR {renderSortIcon("HR")}
                </th>
                <th onClick={() => handleSort("RBI")} className="sortable-header">
                  RBI {renderSortIcon("RBI")}
                </th>
                <th onClick={() => handleSort("AVG")} className="sortable-header">
                  AVG {renderSortIcon("AVG")}
                </th>
                <th onClick={() => handleSort("OBP")} className="sortable-header">
                  OBP {renderSortIcon("OBP")}
                </th>
                <th onClick={() => handleSort("SLG")} className="sortable-header">
                  SLG {renderSortIcon("SLG")}
                </th>
                <th onClick={() => handleSort("OPS")} className="sortable-header">
                  OPS {renderSortIcon("OPS")}
                </th>
              </tr>
            </thead>
            <tbody>
              {unqualifiedPlayers.map((stat, index) => (
                <tr key={index}>
                  <td>{stat.player}</td>
                  <td>{stat.G}</td>
                  <td>{stat.AB}</td>
                  <td>{stat.H}</td>
                  <td>{stat["2B"]}</td>
                  <td>{stat["3B"]}</td>
                  <td>{stat.HR}</td>
                  <td>{stat.RBI}</td>
                  <td>{stat.AVG}</td>
                  <td>{stat.OBP}</td>
                  <td>{stat.SLG}</td>
                  <td>{stat.OPS}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <BottomNavBar />
    </div>
  );
};

export default ViewPlayerStats;
