import React, { useState, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { addPlayer } from "../services/PlayerService";
import { ReactComponent as BackArrow } from "../assets/backArrow.svg";

const positions = ["P", "C", "1B", "2B", "3B", "SS", "LF", "LCF", "RCF", "RF"];
const batsThrows = ["R/R", "L/L", "R/L", "L/R"];
const status = ["Active", "Sub", "Inactive"];

interface Player {
    name: string;
    positions: string[];
    batsThrows: string;
    status: string;
}

const AddPlayer: React.FC = () => {
  const [player, setPlayer] = useState<Player>({
    name: "",
    positions: [],
    batsThrows: batsThrows[0],
    status: status[0]
  });
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (name === "positions") {
      const isChecked = (e.target as HTMLInputElement).checked;
      const updatedPositions = isChecked
        ? [...player.positions, value]
        : player.positions.filter(pos => pos !== value);

      if (updatedPositions.length > 3) {
        setError("You can select up to 3 positions only.");
        return;
      } else {
        setError(null);
      }

      setPlayer(prev => ({
        ...prev,
        positions: updatedPositions
      }));
    } else {
      setPlayer({ ...player, [name]: value });
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (player.positions.length === 0) {
      setError("Please select at least one position.");
      return;
    }
    if (error) return;

    try {
      const addedPlayer = await addPlayer(player);
      console.log("Player added successfully:", addedPlayer);
      navigate("/manage-roster");
    } catch (error) {
      console.error("Failed to add player:", error);
      alert("Error adding player.");
    }
  };

  return (
    <div className="mainMenuContainer">
      <button className="circleBackButton" onClick={() => navigate("/manage-roster")}>
        <BackArrow />
      </button>
      <h2>Add Player</h2>
      <form onSubmit={handleSubmit}>
        <label>
                    Name:
          <input type="text" name="name" value={player.name} onChange={handleChange} required />
        </label>
        <label>
                    Bats / Throws:
          <select name="batsThrows" value={player.batsThrows} onChange={handleChange} required>
            {batsThrows.map(hand => <option key={hand} value={hand}>{hand}</option>)}
          </select>
        </label>
        <label>
                    Status:
          <select name="status" value={player.status} onChange={handleChange} required>
            {status.map(st => <option key={st} value={st}>{st}</option>)}
          </select>
        </label>
        <fieldset className="positionsFieldset">
          <legend className="fieldsetLegend">Positions</legend>
          {positions.map(pos => (
            <label key={pos} className="positionCheckbox">
              <input
                type="checkbox"
                name="positions"
                value={pos}
                checked={player.positions.includes(pos)}
                onChange={handleChange}
              /> {pos}
            </label>
          ))}
          {error && <p className="error">{error}</p>}
        </fieldset>
        <button type="submit" className="actionButton">Submit</button>
      </form>
    </div>
  );
};

export default AddPlayer;
