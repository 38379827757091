import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a new team
export const addTeam = async (team) => {
  try {
    const response = await api.post("/teams", team);
    return response.data;
  } catch (error) {
    console.error("Failed to add team:", error);
    throw error;
  }
};

// Fetch all teams (optional if needed later)
export const getTeams = async () => {
  try {
    const response = await api.get("/teams");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch teams:", error);
    throw error;
  }
};

// Fetch a team by ID
export const getTeamById = async (teamId) => {
  try {
    const response = await api.get(`/teams/${teamId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch team by ID:", error);
    throw error;
  }
};
