import React, { useEffect, useMemo, useState, useCallback } from "react";
import { ReactComponent as BaseballField } from "../assets/baseballField.svg";
import { getHitLocations } from "../services/StatsService";
import { getAllPlayers } from "../services/PlayerService";
import BottomNavBar from "./BottomNavBar";
import TopNavBar from "./TopNavBar";

interface Player {
  _id: string;
  name: string;
  positions: string[];
  batsThrows: string;
  status: string;
  atBats: string[]; // Array of AtBat IDs
}

// Define all possible regions
const allRegions = [
  "1B",
  "2B",
  "3B",
  "SS",
  "Middle",
  "RightField",
  "RightCenterField",
  "CenterField",
  "LeftCenterField",
  "LeftField",
  "DeepRightField",
  "DeepRightCenterField",
  "DeepCenterField",
  "DeepLeftCenterField",
  "DeepLeftField",
  "HomerunRight",
  "HomerunCenter",
  "HomerunLeft",
];

const Heatmap: React.FC = () => {
  const [hitLocations, setHitLocations] = useState<Record<string, number>>({});
  const [players, setPlayers] = useState<Player[]>([]);
  const [selectedPlayer, setSelectedPlayer] = useState<string>("");

  const fetchPlayers = async () => {
    try {
      const data = await getAllPlayers();
      setPlayers(data);
    } catch (error) {
      console.error("Failed to fetch players:", error);
    }
  };

  const fetchHitLocations = async (playerId: string) => {
    try {
      const data = await getHitLocations(playerId);

      // Initialize all regions to 0
      const initializedLocations: Record<string, number> = {};
      allRegions.forEach((region) => {
        initializedLocations[region] = 0;
      });

      // Update regions with fetched data
      Object.entries(data).forEach(([region, count]) => {
        initializedLocations[region] = count as number;
      });

      setHitLocations(initializedLocations);
      console.log("Hit locations:", initializedLocations);
    } catch (error) {
      console.error("Error fetching hit locations:", error);
    }
  };

  useEffect(() => {
    fetchPlayers();
  }, []);

  useEffect(() => {
    if (selectedPlayer) {
      fetchHitLocations(selectedPlayer);
    }
  }, [selectedPlayer]);

  const handlePlayerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPlayer(event.target.value);
  };

  const colors = useMemo(() => ["#0000FF", "#8080FF", "#9999FF", "#D3D3D3", "#FF9999", "#FF6666", "#FF0000"], []);

  const calculateColorAndOpacity = useCallback(
    (count: number) => {
      const maxCount = Math.max(...Object.values(hitLocations));
      if (maxCount === 0) return { color: colors[0], opacity: 0.6 }; // Set default opacity to 0.6

      const normalizedCount = Math.log(count + 1) / Math.log(maxCount + 1);

      let color;
      if (normalizedCount <= 0.125) color = colors[0]; // Dark Blue
      else if (normalizedCount <= 0.25) color = colors[1]; // Medium Blue
      else if (normalizedCount <= 0.375) color = colors[2]; // Light Blue
      else if (normalizedCount <= 0.5) color = colors[3]; // Light Grey
      else if (normalizedCount <= 0.625) color = colors[4]; // Light Red
      else if (normalizedCount <= 0.75) color = colors[5]; // Medium Red
      else color = colors[6]; // Dark Red

      return { color, opacity: 0.6 };
    },
    [colors, hitLocations]
  );

  useEffect(() => {
    // Before selecting a player, all regions should be transparent
    allRegions.forEach((region) => {
      const element = document.getElementById(`region-${region}`);
      if (element) {
        element.style.fill = "none";
        element.style.opacity = "0";
      }
    });

    if (selectedPlayer) {
      // After selecting a player, update the regions based on hit locations
      Object.entries(hitLocations).forEach(([key, count]) => {
        const region = document.getElementById(`region-${key}`);
        if (region) {
          const { color, opacity } = calculateColorAndOpacity(count);
          region.style.fill = color;
          region.style.opacity = opacity.toString();
          console.log(`Setting color for ${key}: ${color} with opacity: ${opacity}`);
        } else {
          console.warn(`Region not found for key: ${key}`);
        }
      });
    }
  }, [hitLocations, selectedPlayer, calculateColorAndOpacity]);

  return (
    <div className="mainMenuContainer">
      <TopNavBar />
      <h2>Hitting Heatmap</h2>
      <div className="player-select-container">
        <label htmlFor="player-select">Select a player:</label>
        <select id="player-select" onChange={handlePlayerChange} value={selectedPlayer}>
          <option value=""></option>
          {players.map((player) => (
            <option key={player._id} value={player._id}>
              {player.name}
            </option>
          ))}
        </select>
      </div>
      <BaseballField />
      <BottomNavBar />
    </div>
  );
};

export default Heatmap;
